<template>
  <div>
    <Dropdown
      ref="dropdown"
      label="name"
      :options="stockStores"
      @input="onInput"
      :value="value"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from '@/components/inputstock/details/Dropdown.vue';
import { EventBus } from "@/eventBus.js";

export default {
  mixins: [DetailUpdateMixin],
  props: {
    filterCropfields: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Dropdown
  },
  computed: {
    ...mapGetters({
      stockStores: "stockStores/stockStores",
      currentStockStore: "stockStores/currentStockStore"
    }),
    stockStoresOptions() {
      return this.stockStores || []
    },
    value() {
      // get the value of current edited entry or current entry
      return this.stockStoresOptions.find(c => c.id == (this.currentEditedEntry.stockStore || this.currentEntry.stockStore))
      // if previous value does not exist yet, get the first stock store
      || (this.stockStoresOptions.length > 0 && this.stockStoresOptions[0])
    },
    disabled() {
      // if there is any cropfield, disable crop change
      if(this.currentEditedEntry.cropFields) return this.currentEditedEntry.cropFields.length > 0
      return this.currentEntry.cropFields && this.currentEntry.cropFields.length > 0
    }
  },
  async mounted() {
    this.$refs["dropdown"].setValue(this.value)

    // useful when there is no stockStore value yet but we selected automatically fist stock store of array
    if(this.currentEditedEntry.stockStore != this.value.id) {
      this.onInput(this.value)
    }
  },
  methods: {
    async onInput(stockStore) {
      await this.$store.dispatch(this.storeEditAction, {
        stockStore: stockStore?.id || null,
      });

      await this.getStockStore({id: stockStore?.id});
      await this.$store.dispatch("tour/getInputForm");

      if(this.filterCropfields) {
        this.filterCropfieldsOnStockStore();
      }
    },

    /**
     * If the prop 'filterCropfields' is set to true
     * this component will filter stockstore value in cropfields
     */
    async filterCropfieldsOnStockStore() {
      // fetch cropfields
      await this.fetchCropfields({stockStore: this.value?.id});
      // plot cropfields
      EventBus.$emit("plotFields", true)
    },

    ...mapActions({
      fetchStockStores: "stockStores/fetchStockStores",
      fetchCropfields: "cropfield/fetchEntries",
      getStockStore: "stockStores/getStockStore",
    })
  }
};
</script>

<style lang="scss" scoped>
</style>
